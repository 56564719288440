.background {
    background-color: #191919;
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0%;
    left: 0%;
}

.container {
    max-width: 50%;
    max-height: 50%;
    border-radius: 25px;
    padding: 25px 25px 25px 25px;
    background: #cb93ff;
    height: 50%;
    width: 50%;
    left: 25%;
    top: 25%;
    position: relative;
    justify-content: center;
    text-align: center;
}

p {
    position: relative;
    top: 15%;
    margin: 10%;
    text-align: center;
}

.flipper-button {
    margin: 2.5% 5% 2.5% 5%;
    background-color: #93cbff;
    border-radius: 30px;
    padding: 10px;
    min-width: 100px;
    min-height: 35px;
    font-size: 15px;
    font-family: Arial;
    font-weight: bold;
    color: white;
    border: hidden;
    
}

.flipper-button:active {
    margin: 10px auto;
    background-color: rgb(101, 157, 209);
    border-radius: 30px;
    padding: 10px;
    min-width: 100px;
    min-height: 35px;
    font-size: 15px;
    font-family: Arial;
    font-weight: bold;
    color: white;
    border: hidden;
}

.button-pressed {
    margin: 10px auto;
    background-color: rgb(101, 157, 209);
    border-radius: 30px;
    padding: 10px;
    min-width: 100px;
    min-height: 35px;
    font-size: 15px;
    font-family: Arial;
    font-weight: bold;
    color: white;
    border: hidden;
}

input {
    text-align: center;
    margin: 1%;
    min-height: 7.5%;
    min-width: 25%;
    height: 5%;
    padding: 3.2%;
    border-radius: 30px;
    border: hidden;
    font-size: 200%;
}
